import { Typography } from "@mui/material"
import React from "react"

export function H2({ title }: { title: string }) {
  return (
    <Typography
      variant="h2"
      color={"#fff"}
      sx={{
        fontSize: "1.125rem",
        fontWeight: 600,
        lineHeight: "120%",
        my: "0.75rem",
      }}
    >
      {title}
    </Typography>
  )
}
