import {
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React from "react"
import { useAppContext } from "../Context"

export function MediaSelectForm({
  name,
  icon,
  options,
  currentOption,
  onChange,
}) {
  const context = useAppContext()
  const disabled = !context.stream
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <FormControl
      variant="standard"
      sx={{ color: "grey", width: isMobile ? "70%" : "50%" }}
    >
      <Select
        value={currentOption}
        disabled={disabled}
        onChange={onChange}
        displayEmpty // Required to show placeholder without a label
        startAdornment={
          // Add an icon to the left of the Select
          <InputAdornment position="start">{icon}</InputAdornment>
        }
        inputProps={{
          "aria-label": name, // Accessibility improvement since the label is removed
        }}
        sx={{
          // Adjust font color directly on the Select component
          ".MuiSelect-select": {
            color: "grey",
          },
          "& .MuiSvgIcon-root": {
            // Ensure icons match the font color
            color: disabled ? "#ccc" : "grey",
          },
          // Remove underline
          "&:before": {
            borderBottom: "none",
          },
          "&.Mui-disabled:before": {
            borderBottom: "none",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.deviceId} value={option.deviceId}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
