import { Stack, Typography } from "@mui/material"
import React from "react"

export function TitleTextButton({
  text,
  title,
  button,
}: {
  text: JSX.Element
  title: string
  button: JSX.Element
}) {
  return (
    <Stack direction="column" spacing={2} alignItems={"center"}>
      <Typography
        variant="h4"
        sx={{ fontSize: "40px", fontWeight: 500, lineHeight: "110%" }}
      >
        {title}
      </Typography>
      {button}
      <Typography variant="body1">{text}</Typography>
    </Stack>
  )
}
