import React from "react"
import { Params } from "react-router-dom"
import PasswordDialog from "../../components/password"
import { RoomLoader } from "../../types/Call"
import { State } from "../../types/State"
import ErrorPage from "../error-page"
import LiveRoom from "../MainScreen/LiveKitRoomScreen"
import { PostScreen } from "../PostScreen/PostScreen"
import { PreScreen } from "../PreScreen/PreScreen"

const verySecretPassword = "ynVdx6Cz6Y"

export const getRouterData = (
  state: State,
  roomLoader: ({ params }: { params: Params }) => RoomLoader,
) => {
  if (
    state.userEnteredPassword != null &&
    state.userEnteredPassword !== verySecretPassword
  ) {
    return [
      {
        path: "/",
        element: <PasswordDialog />,
        errorElement: <ErrorPage />,
      },
    ]
  }
  return [
    {
      path: "/",
      element: <PreScreen />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/:id",
      element: <PreScreen />,
      errorElement: <ErrorPage />,
    },
    {
      path: `rooms/:id/`,
      element: <LiveRoom />,
      loader: roomLoader,
    },
    {
      path: `rooms/:id/post`,
      element: <PostScreen />,
      loader: roomLoader,
    },
  ]
}
