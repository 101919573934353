import React, { useState } from "react"

import AspectRatio from "@mui/joy/AspectRatio"

import { Box, useMediaQuery, useTheme } from "@mui/material"
import { MainButton } from "../../components/mainButton"
import { SnackbarWithText } from "../../components/snackbar"
import { UserVideo } from "../../components/UserVideo/UserVideo"
import { ControlVideoWrapper } from "../../components/VideoWrappers/ControlVideoWrapper"
import { getMedia } from "../../lib/getMedia"
import { StateAction } from "../../types/State"
import { SnackbarType } from "../../types/UI"
import { useAppContext, useAppDispatchContext } from "../Context"

export function VideoOrPlaceholder() {
  const context = useAppContext()
  const dispatch = useAppDispatchContext()
  const stream = context.stream
  const [errorOpen, setErrorOpen] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const content = (
    <AspectRatio
      ratio={isMobile ? "4/3" : "16/9"}
      sx={{
        width: isMobile ? "90vw" : undefined,
        maxWidth: 600,
        borderRadius: "12px",
        bgcolor: "transparent",
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          backgroundColor: "#10072B",
          boxShadow: "0px 4px 24px 0px rgba(26, 10, 70, 0.30)",
        }}
      >
        {stream != null &&
        stream.getTracks != null &&
        stream.getTracks().length > 0 ? (
          <UserVideo />
        ) : (
          <MainButton
            onClick={async () => {
              const stream = await getMedia(
                null,
                () => setErrorOpen(true),
                context,
              )
              console.log("stream", stream)
              if (stream != null) {
                dispatch({ type: StateAction.setStream, payload: stream })
              }
            }}
            disabled={
              stream != null &&
              stream.getTracks != null &&
              stream.getTracks().length > 0
            }
            text="Allow microphone and camera"
            small={isMobile}
          />
        )}
      </Box>
    </AspectRatio>
  )

  return (
    <>
      <ControlVideoWrapper>{content}</ControlVideoWrapper>
      <SnackbarWithText
        open={errorOpen}
        severity={SnackbarType.error}
        text="Please go to your settings and allow access to your camera and microphone!"
        handleClose={() => setErrorOpen(false)}
      />
    </>
  )
}
