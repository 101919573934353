import { auth } from "../app/App"
import { Endpoints, Method, ReturnTypes, SecondPathParams } from "../types/Api"

export const isLocalDev = process.env.NODE_ENV === "development"

export const isDev = isLocalDev || window.location.href.includes("dev")

export const backendUrl = isLocalDev
  ? "http://127.0.0.1:8000/"
  : isDev
    ? "https://api-dev.bey.chat/"
    : "https://api.bey.chat/"

const callApi = async (
  endpoint: Endpoints,
  method = Method.GET,
  id?: string,
  body?: any,
  secondPathParam?: SecondPathParams,
  returnType = ReturnTypes.json,
) => {
  const idToken = await auth.currentUser.getIdToken()
  const response = await fetch(
    secondPathParam
      ? `${backendUrl}${endpoint}/${id}/${secondPathParam}`
      : id
        ? `${backendUrl}${endpoint}/${id}`
        : `${backendUrl}${endpoint}`,
    {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    },
  )
  if (response.status !== 200) {
    const json = await response.json()
    throw new Error(`${response.status}: ${(json as any).detail}`)
  }
  if (returnType === ReturnTypes.blob) {
    return response.blob()
  } else {
    return response.json()
  }
}

export const postOffer = async (body: any) => {
  return callApi(Endpoints.offer, Method.POST, undefined, body)
}

export const getVideo = async (id: string) => {
  return callApi(Endpoints.video, Method.GET, `${id}?${Date.now()}`)
}

export const getLivekitToken = async (room_id: string) => {
  return callApi(Endpoints.getToken, Method.POST, room_id, { room_id })
}

export const deleteLivekitRoom = async (room_id: string) => {
  return callApi(Endpoints.deleteRoom, Method.POST, room_id, { room_id })
}

export const getDefaultAgentId = isDev ? "experimental" : "felix-bey"
