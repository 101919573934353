import MicIcon from "@mui/icons-material/Mic"
import MicOffIcon from "@mui/icons-material/MicOff"
import React from "react"
import { useAppContext, useAppDispatchContext } from "../../app/Context"
import { StateAction } from "../../types/State"
import { StyledButton } from "./StyledButton"

export function MicToggleButton({ getStyle = null }) {
  const context = useAppContext()
  const dispatch = useAppDispatchContext()
  const active = context.audioEnabled
  const style = getStyle ? getStyle(active) : {}

  function onClick() {
    dispatch({ type: StateAction.toggleAudio })
  }

  return (
    <StyledButton
      active={active}
      activeTip="Mute Audio"
      inactiveTip="Unmute Audio"
      onClick={onClick}
      style={style}
    >
      {active ? (
        <MicIcon titleAccess="Mute" />
      ) : (
        <MicOffIcon titleAccess="Unmute" />
      )}
    </StyledButton>
  )
}
