import { Box, Typography } from "@mui/material"
import React from "react"
import LogoImage from "../assets/logo.png"

export function Logo() {
  const redirectToHome = () => {
    window.location.href = "/"
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        flexGrow: 1,
      }}
      onClick={redirectToHome}
    >
      <img
        src={LogoImage}
        alt="Logo"
        style={{ width: "50px", height: "50px", marginRight: "8px" }} // Adjust size as needed
      />
      <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
        Beyond Presence
      </Typography>
    </Box>
  )
}
