import React from "react"

import { Box, Stack, useMediaQuery, useTheme } from "@mui/material"
import { useAppContext } from "../../app/Context"
import { MicToggleButton } from "../IconButtons/MicToggleButton"
import { VideoToggleButton } from "../IconButtons/VideoToggleButton"

export function ControlVideoWrapper({ children }) {
  const context = useAppContext()
  const stream = context.stream
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  function getVideoControlIconStyle(active) {
    return {
      borderRadius: "50%",
      width: "56px",
      height: "56px",
      margin: "12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      backgroundColor: active ? "" : "#ea4335",
      border: active ? "1px solid white" : "",
    }
  }

  const controls =
    stream && stream.getTracks ? (
      <Box
        sx={{
          zIndex: "tooltip",
          position: "relative",
          marginTop: "-5rem",
        }}
      >
        <Stack direction="row" spacing={10} justifyContent={"center"}>
          <MicToggleButton getStyle={getVideoControlIconStyle} />
          <VideoToggleButton getStyle={getVideoControlIconStyle} />
        </Stack>
      </Box>
    ) : null
  return (
    <Box sx={{ p: isMobile ? "2rem" : undefined }}>
      {children}
      {controls}
    </Box>
  )
}
