import { addDoc, collection, doc, setDoc } from "firebase/firestore"
import { db } from "../app/App"
import { Call, ChatMessage } from "../types/Call"
import { Collections } from "../types/Db"
import { User } from "../types/User"

export const addCallDb = async (
  user: User,
  avatarId: string,
): Promise<string> => {
  const conversationRef = collection(db, Collections.calls)
  const conversation = {
    startedAt: new Date(),
    userId: user.uid,
    avatarId,
  }
  return (await addDoc(conversationRef, conversation)).id
}

export const updateCallDb = async (call: Call): Promise<void> => {
  const conversationRef = doc(db, Collections.calls, call.id)
  await setDoc(conversationRef, call, { merge: true })
}

export const addMessageToCallDb = async (
  conversationId: string,
  message: ChatMessage,
): Promise<void> => {
  const conversationRef = collection(
    db,
    Collections.calls,
    conversationId,
    Collections.messages,
  )
  await addDoc(conversationRef, message)
}
