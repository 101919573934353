import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material"
import Button from "@mui/material/Button"
import Rating from "@mui/material/Rating"
import Typography from "@mui/material/Typography"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Header } from "../../components/header"
import { MainButton } from "../../components/mainButton"
import { SnackbarWithText } from "../../components/snackbar"
import { logAnalyticsEvent } from "../../external/analytics"
import { updateCallDb } from "../../external/firestore"
import { AnalyticsEvents } from "../../types/Analytics"
import { StateAction } from "../../types/State"
import { SnackbarType } from "../../types/UI"
import { useAppContext, useAppDispatchContext } from "../Context"

export function PostScreen() {
  const dispatch = useAppDispatchContext()
  const context = useAppContext()
  const email = context.user.email

  const navigate = useNavigate()
  const [rating, setRating] = useState(0)
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [summaryOpen, setSummaryOpen] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [headline, setHeadline] = useState("You left the meeting")
  const [dialogOpen, setDialogOpen] = useState(false)
  const [feedbackText, setFeedbackText] = useState("")

  const handleEndCallAndSummary = async () => {
    const call = { ...context.call, endedAt: new Date() }
    dispatch({ type: StateAction.setCall, payload: call })
    await updateCallDb(call)
    dispatch({ type: StateAction.end })
    setSummaryOpen(true)
    console.log("A summary of the meeting was sent to ", email)
    setButtonsDisabled(true)
    setHeadline("Your meeting has ended")
    logAnalyticsEvent(AnalyticsEvents.SummaryRequested)
  }

  const handleRatingChange = async (event, newRating) => {
    setRating(newRating)
    setDialogOpen(true)
    const call = { ...context.call, rating: newRating }
    dispatch({ type: StateAction.setCall, payload: call })
    await updateCallDb(call)
    console.log(`Rating: ${newRating}`)
    logAnalyticsEvent(AnalyticsEvents.Rating, { rating: newRating })
  }

  const handleDialogSubmit = async () => {
    if (feedbackText) {
      const call = { ...context.call, feedback: feedbackText }
      dispatch({ type: StateAction.setCall, payload: call })
      await updateCallDb(call)
      logAnalyticsEvent(AnalyticsEvents.Feedback, { feedback: feedbackText })
    }
    handleDialogClose()
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    setFeedbackOpen(true)
  }

  const handleClose = () => {
    setFeedbackOpen(false)
    setSummaryOpen(false)
    setErrorOpen(false)
  }

  const rejoin = async () => {
    logAnalyticsEvent(AnalyticsEvents.Rejoined)
    navigate(`/rooms/${context.call.id}`)
  }

  return (
    <>
      <Header />
      <Stack
        direction={"column"}
        spacing={4}
        sx={{
          height: "90vh",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="h4">{headline}</Typography>
        <MainButton onClick={rejoin} disabled={buttonsDisabled} text="Rejoin" />
        <Button
          variant="text"
          onClick={handleEndCallAndSummary}
          disabled={buttonsDisabled}
          sx={{ textTransform: "none" }}
        >
          End call and send me a summary
        </Button>
        <br></br>
        <Box>
          <Typography component="legend">How did you like the call?</Typography>
          <Rating
            name="simple-controlled"
            value={rating}
            onChange={handleRatingChange}
          />
        </Box>
        <SnackbarWithText
          open={errorOpen}
          severity={SnackbarType.error}
          text="Please go to your settings and allow access to your camera and microphone!"
          handleClose={handleClose}
        />
        <SnackbarWithText
          open={feedbackOpen}
          severity={SnackbarType.success}
          text="We recorded your feedback. Thanks for helping us improve!"
          handleClose={handleClose}
        />
        <SnackbarWithText
          open={summaryOpen}
          severity={SnackbarType.success}
          text={`A summary of the meeting was sent to ${email}`}
          handleClose={handleClose}
        />
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Help us improve</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please let us know how we can improve our service.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="feedback"
              label="Feedback"
              type="text"
              fullWidth
              variant="standard"
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogSubmit}>Submit</Button>
            <Button onClick={handleDialogClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  )
}
