import * as Sentry from "@sentry/react"
import { signOut } from "firebase/auth"
import { auth } from "../app/App"

export const signUserOut = async () => {
  await signOut(auth).catch((error) => {
    Sentry.captureException(error)
    console.error(error)
  })
}
