import React, { useEffect, useRef } from "react"
import { useAppContext } from "../../app/Context"

export function UserVideo() {
  const videoRef = useRef(null)
  const appContext = useAppContext()
  const videoStream = appContext.stream

  useEffect(() => {
    if (videoStream) {
      try {
        videoRef.current.srcObject = videoStream
      } catch (error) {
        console.error(error)
      }
    }
  }, [videoStream])

  return (
    <video
      ref={videoRef}
      autoPlay
      muted
      playsInline
      className="scale-x-[-1] rounded-[12px] m-2"
    ></video>
  )
}
