import * as Sentry from "@sentry/react"
import { State } from "../types/State"
import { replaceStreamTrack } from "../utils/media"

export async function getMedia(
  constraints: MediaStreamConstraints | null = null,
  onError: (error: Error) => void,
  context: State,
): Promise<MediaStream | null> {
  if (constraints === null) {
    constraints = {
      audio: true,
      video: true,
    }
  }
  try {
    const mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
    if (context.audioDevice) {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: context.audioDevice } },
      })
      replaceStreamTrack(mediaStream, audioStream.getAudioTracks()[0], "audio")
    }
    if (context.videoDevice) {
      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: context.videoDevice } },
      })
      replaceStreamTrack(mediaStream, videoStream.getVideoTracks()[0], "video")
    }
    if (context.speakerDevice) {
      const audioOutput = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: context.speakerDevice } },
      })
      replaceStreamTrack(mediaStream, audioOutput.getAudioTracks()[0], "audio")
    }
    return mediaStream
  } catch (error) {
    Sentry.captureException(error)
    console.error("Error accessing media devices.", error)
    onError(error)
    return null
  }
}
