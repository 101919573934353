import type {
  MessageDecoder,
  MessageEncoder,
  TrackReferenceOrPlaceholder,
  WidgetState,
} from "@livekit/components-core"
import {
  isEqualTrackRef,
  isTrackReference,
  isWeb,
  log,
} from "@livekit/components-core"
import {
  CarouselLayout,
  Chat,
  ConnectionStateToast,
  ControlBar,
  FocusLayout,
  FocusLayoutContainer,
  GridLayout,
  LayoutContextProvider,
  MessageFormatter,
  ParticipantTile,
  RoomAudioRenderer,
  useChat,
  useCreateLayoutContext,
  useDataChannel,
  useParticipants,
  usePinnedTracks,
  useTracks,
} from "@livekit/components-react"
import { useMediaQuery, useTheme } from "@mui/material"
import {
  LocalParticipant,
  RemoteParticipant,
  RoomEvent,
  Track,
} from "livekit-client"
import * as React from "react"
import { logAnalyticsEvent } from "../../external/analytics"
import { addMessageToCallDb } from "../../external/firestore"
import { AnalyticsEvents } from "../../types/Analytics"
import {
  ChatMessage,
  ChatMessageInputType,
  ChatMessageSender,
} from "../../types/Call"
import { useAppContext } from "../Context"
/**
 * @public
 */
export interface VideoConferenceProps
  extends React.HTMLAttributes<HTMLDivElement> {
  setParticipants: (
    participants: (RemoteParticipant | LocalParticipant)[],
  ) => void
  chatMessageFormatter?: MessageFormatter
  chatMessageEncoder?: MessageEncoder
  chatMessageDecoder?: MessageDecoder
  /** @alpha */
  SettingsComponent?: React.ComponentType
}

export function MyVideoConference({
  chatMessageFormatter,
  chatMessageDecoder,
  chatMessageEncoder,
  setParticipants,
  SettingsComponent,
  ...props
}: VideoConferenceProps) {
  const context = useAppContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const { chatMessages, send } = useChat()
  const participants = useParticipants()

  React.useEffect(() => {
    setParticipants(participants)
  }, [participants])

  React.useEffect(() => {
    const req = async () => {
      if (chatMessages.length === 0) {
        return
      }
      const lastMessage = chatMessages[chatMessages.length - 1]
      const messageToSend: ChatMessage = {
        message: lastMessage.message,
        sentAt: new Date(lastMessage.timestamp),
        sender: lastMessage.from.isAgent
          ? ChatMessageSender.ai
          : ChatMessageSender.user,
      }

      logAnalyticsEvent(
        messageToSend.sender === ChatMessageSender.ai
          ? chatMessages.length === 1
            ? AnalyticsEvents.GreetingMessage
            : AnalyticsEvents.AnswerProvided
          : AnalyticsEvents.QuestionAsked,
        {
          input_type: ChatMessageInputType.text,
        },
      )
      await addMessageToCallDb(context.call.id, messageToSend)
    }
    req()
    return () => {}
  }, [chatMessages])

  const onMessage = (msg: any) => {
    const message = new TextDecoder("utf-8").decode(msg.payload)
    send(message)
  }

  useDataChannel("transcript", onMessage)

  const [widgetState, setWidgetState] = React.useState<WidgetState>({
    showChat: true,
    unreadMessages: 0,
    showSettings: false,
  })
  const lastAutoFocusedScreenShareTrack =
    React.useRef<TrackReferenceOrPlaceholder | null>(null)

  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { updateOnlyOn: [RoomEvent.ActiveSpeakersChanged], onlySubscribed: false },
  )

  const widgetUpdate = (state: WidgetState) => {
    log.debug("updating widget state", state)
    setWidgetState(state)
  }

  const layoutContext = useCreateLayoutContext()

  const screenShareTracks = tracks
    .filter(isTrackReference)
    .filter((track) => track.publication.source === Track.Source.ScreenShare)

  const focusTrack = usePinnedTracks(layoutContext)?.[0]
  const carouselTracks = tracks.filter(
    (track) => !isEqualTrackRef(track, focusTrack),
  )

  React.useEffect(() => {
    // If screen share tracks are published, and no pin is set explicitly, auto set the screen share.
    if (
      screenShareTracks.some((track) => track.publication.isSubscribed) &&
      lastAutoFocusedScreenShareTrack.current === null
    ) {
      log.debug("Auto set screen share focus:", {
        newScreenShareTrack: screenShareTracks[0],
      })
      layoutContext.pin.dispatch?.({
        msg: "set_pin",
        trackReference: screenShareTracks[0],
      })
      lastAutoFocusedScreenShareTrack.current = screenShareTracks[0]
    } else if (
      lastAutoFocusedScreenShareTrack.current &&
      !screenShareTracks.some(
        (track) =>
          track.publication.trackSid ===
          lastAutoFocusedScreenShareTrack.current?.publication?.trackSid,
      )
    ) {
      log.debug("Auto clearing screen share focus.")
      layoutContext.pin.dispatch?.({ msg: "clear_pin" })
      lastAutoFocusedScreenShareTrack.current = null
    }
    if (focusTrack && !isTrackReference(focusTrack)) {
      const updatedFocusTrack = tracks.find(
        (tr) =>
          tr.participant.identity === focusTrack.participant.identity &&
          tr.source === focusTrack.source,
      )
      if (
        updatedFocusTrack !== focusTrack &&
        isTrackReference(updatedFocusTrack)
      ) {
        layoutContext.pin.dispatch?.({
          msg: "set_pin",
          trackReference: updatedFocusTrack,
        })
      }
    }
  }, [
    screenShareTracks
      .map(
        (ref) => `${ref.publication.trackSid}_${ref.publication.isSubscribed}`,
      )
      .join(),
    focusTrack?.publication?.trackSid,
  ])

  React.useEffect(() => {
    if (focusTrack == null && !isMobile) {
      const agentTrack = tracks.find(
        (track) =>
          track.source === Track.Source.Camera &&
          track.participant.isSpeaking &&
          track.participant.isAgent,
      )
      if (agentTrack) {
        layoutContext.pin.dispatch?.({
          msg: "set_pin",
          trackReference: agentTrack,
        })
      }
    }
  }, [tracks])

  // useWarnAboutMissingStyles();

  return (
    <div className="lk-video-conference" {...props}>
      {isWeb() && (
        <LayoutContextProvider
          value={layoutContext}
          // onPinChange={handleFocusStateChange}
          onWidgetChange={widgetUpdate}
        >
          <div className="lk-video-conference-inner">
            {!focusTrack ? (
              <div className="lk-grid-layout-wrapper">
                <GridLayout tracks={tracks}>
                  <ParticipantTile />
                </GridLayout>
              </div>
            ) : (
              <div className="lk-focus-layout-wrapper">
                <FocusLayoutContainer>
                  <CarouselLayout tracks={carouselTracks}>
                    <ParticipantTile />
                  </CarouselLayout>
                  {focusTrack && <FocusLayout trackRef={focusTrack} />}
                </FocusLayoutContainer>
              </div>
            )}
            <ControlBar
              controls={{
                chat: isMobile ? true : false,
                screenShare: false,
                settings: !!SettingsComponent,
              }}
            />
          </div>
          <Chat
            style={{
              display: widgetState.showChat
                ? "grid"
                : isMobile
                  ? "none"
                  : "grid",
            }}
            messageFormatter={chatMessageFormatter}
            messageEncoder={chatMessageEncoder}
            messageDecoder={chatMessageDecoder}
          />
          {SettingsComponent && (
            <div
              className="lk-settings-menu-modal"
              style={{ display: widgetState.showSettings ? "block" : "none" }}
            >
              <SettingsComponent />
            </div>
          )}
        </LayoutContextProvider>
      )}
      <RoomAudioRenderer />
      <ConnectionStateToast />
    </div>
  )
}
