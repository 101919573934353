import { logEvent } from "firebase/analytics"
import mixpanel, { Dict } from "mixpanel-browser"
import { analytics } from "../app/App"
import { AnalyticsEvents } from "../types/Analytics"
import { isLocalDev } from "../utils/general"

export const logAnalyticsEvent = (
  eventName: AnalyticsEvents,
  eventProperties?: Dict,
) => {
  if (!isLocalDev) {
    mixpanel.track(eventName, eventProperties)
    logEvent(analytics, eventName, eventProperties)
  }
}
