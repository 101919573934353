export const replaceStreamTrack = (
  stream: MediaStream,
  newTrack: MediaStreamTrack,
  kind: string,
): MediaStream => {
  let trackToReplace =
    kind === "audio" ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0]
  if (trackToReplace) {
    stream.removeTrack(trackToReplace)
  }
  stream.addTrack(newTrack)
  return stream
}
