import { createContext, useContext } from "react"
import { Action, State } from "../types/State"

export const AppContext = createContext<State>(null)

export const AppDispatchContext = createContext<React.Dispatch<Action>>(null)

export function useAppContext() {
  return useContext(AppContext)
}

export function useAppDispatchContext() {
  return useContext(AppDispatchContext)
}
