import React, { useEffect, useState } from "react"

import MicIcon from "@mui/icons-material/Mic"
import VideocamIcon from "@mui/icons-material/Videocam"

import { Stack, useMediaQuery, useTheme } from "@mui/material"
import { StateAction } from "../../types/State"
import { replaceStreamTrack } from "../../utils/media"
import { useAppContext, useAppDispatchContext } from "../Context"
import { MediaSelectForm } from "./MediaSelectForm"

export function MediaSelect() {
  const [microphone, setMicrophone] = useState<string>("")
  const [camera, setCamera] = useState<string>("")
  const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([])
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([])

  const context = useAppContext()
  const stream = context.stream
  const dispatch = useAppDispatchContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    const requestMedia = async () => {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const foundMicrophones: MediaDeviceInfo[] = []
        const foundCameras: MediaDeviceInfo[] = []
        devices.forEach((device) => {
          if (device.kind === "audioinput") {
            foundMicrophones.push(device)
          } else if (device.kind === "videoinput") {
            foundCameras.push(device)
          }
        })
        setMicrophones(foundMicrophones)
        setCameras(foundCameras)
        if (foundMicrophones.length > 0) {
          setMicrophone(foundMicrophones[0].deviceId)
          dispatch({
            type: StateAction.setAudioDevice,
            payload: foundMicrophones[0].deviceId,
          })
        }
        if (foundCameras.length > 0) {
          setCamera(foundCameras[0].deviceId)
          dispatch({
            type: StateAction.setVideoDevice,
            payload: foundCameras[0].deviceId,
          })
        }
      })
    }
    requestMedia()
  }, [stream])

  async function onMicrophoneChange(e) {
    const deviceId = e.target.value
    setMicrophone(deviceId)
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: { exact: deviceId } },
    })
    replaceStreamTrack(stream, audioStream.getAudioTracks()[0], "audio")
    dispatch({ type: StateAction.setAudioDevice, payload: deviceId })
  }

  async function onCameraChange(e) {
    const deviceId = e.target.value
    setCamera(deviceId)
    const videoStream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: { exact: deviceId } },
    })
    replaceStreamTrack(stream, videoStream.getVideoTracks()[0], "video")
    dispatch({ type: StateAction.setVideoDevice, payload: deviceId })
  }

  return (
    <>
      {stream != null && stream.getTracks != null ? (
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{ alignItems: "center" }}
        >
          {microphones.length > 0 && (
            <MediaSelectForm
              name="Microphone"
              icon={<MicIcon />}
              options={microphones}
              currentOption={microphone}
              onChange={onMicrophoneChange}
            ></MediaSelectForm>
          )}
          {cameras.length > 0 && (
            <MediaSelectForm
              name="Camera"
              icon={<VideocamIcon />}
              options={cameras}
              currentOption={camera}
              onChange={onCameraChange}
            ></MediaSelectForm>
          )}
        </Stack>
      ) : null}
    </>
  )
}
