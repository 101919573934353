import { Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import React, { useEffect } from "react"
import { AccountMobile } from "../../components/accountMobile"
import { Header } from "../../components/header"
import { logAnalyticsEvent } from "../../external/analytics"
import { AnalyticsEvents } from "../../types/Analytics"
import { MediaSetup } from "./MediaSetup"
import { UserInput } from "./UserInput"

const sendAnalyticsEvent = () => {
  logAnalyticsEvent(AnalyticsEvents.AgentVisited)
}

export function PreScreen() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    sendAnalyticsEvent()
  }, [sendAnalyticsEvent])

  return (
    <Stack direction={"column"} spacing={2}>
      <Header />
      <Stack
        direction={"column"}
        spacing={2}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {isMobile ? (
          <Typography variant="h4">{"Ready to join?"}</Typography>
        ) : null}
        <Grid
          container
          spacing={isMobile ? 0 : 10}
          justifyContent="center"
          alignItems="center"
          sx={{ p: isMobile ? 0 : 10 }}
        >
          <Grid item md={1} xl={2}></Grid>
          <Grid item md={6} xl={5}>
            <MediaSetup />
          </Grid>
          <Grid item md={4} xl={3}>
            <UserInput />
          </Grid>
          <Grid item md={1} xl={2}></Grid>
        </Grid>
        {isMobile ? <AccountMobile /> : null}
      </Stack>
    </Stack>
  )
}
