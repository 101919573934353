import { LiveKitRoom } from "@livekit/components-react"
import "@livekit/components-styles"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { logAnalyticsEvent } from "../../external/analytics"
import { AnalyticsEvents } from "../../types/Analytics"
import {
  deleteLivekitRoom,
  getLivekitToken,
  isDev,
  isLocalDev,
} from "../../utils/general"
import { useAppContext } from "../Context"
import { MyVideoConference } from "./MyVideoConference"

const serverUrl = isLocalDev
  ? "ws://localhost:7880"
  : isDev
    ? "wss://dev-38rwe5wn.livekit.cloud"
    : "wss://prod-w0h88kyi.livekit.cloud"

export default function LiveRoom() {
  const context = useAppContext()
  const [token, setToken] = useState("")
  const navigate = useNavigate()
  const { id } = useParams()
  const [participants, setParticipants] = useState([])

  useEffect(() => {
    const req = async () => {
      const tokenFromBe = await getLivekitToken(id)
      setToken(tokenFromBe)
    }
    req()

    const idleTimeout = setTimeout(
      () => {
        logAnalyticsEvent(AnalyticsEvents.IdleTimeout)
        navigate("/")
      },
      1000 * 60 * 15,
    )
    return () => clearTimeout(idleTimeout)
  }, [])

  const onDisconnected = async () => {
    if (participants.every((p) => p.isLocal || p.isAgent)) {
      await deleteLivekitRoom(id)
    }
    navigate("/rooms/" + id + "/post")
  }

  return (
    <LiveKitRoom
      video={{ deviceId: context.videoDevice }}
      audio={{ deviceId: context.audioDevice }}
      token={token}
      serverUrl={serverUrl}
      // Use the default LiveKit theme for nice styles.
      data-lk-theme="default"
      style={{ height: "100dvh" }}
      onDisconnected={onDisconnected}
    >
      <MyVideoConference setParticipants={setParticipants} />
    </LiveKitRoom>
  )
}
