export enum AnalyticsEvents {
  AgentVisited = "Agent visited",
  MeetingJoined = "Meeting joined",
  GreetingMessage = "Greeting message",
  QuestionAsked = "Question asked",
  AnswerProvided = "Answer provided",
  CallEnded = "Call ended",
  Rejoined = "Rejoined",
  SummaryRequested = "Summary requested",
  Rating = "Rating",
  Feedback = "Feedback",
  IdleTimeout = "Idle timeout",
}
