import { Fab } from "@mui/material"
import React from "react"

export function MainButton({
  onClick,
  disabled,
  text,
  small = false,
}: {
  onClick: () => void
  disabled: boolean
  text: string
  small?: boolean
}) {
  return (
    <Fab
      color="primary"
      variant="extended"
      onClick={onClick}
      disabled={disabled}
      sx={{
        textTransform: "none",
        width: small ? "292px" : "348px",
        height: "53px",
        borderRadius: "50px",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "130%",
      }}
    >
      {text}
    </Fab>
  )
}
