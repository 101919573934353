import React from "react"

import { Stack } from "@mui/material"
import { MediaSelect } from "./MediaSelect"
import { VideoOrPlaceholder } from "./VideoOrPlaceholder"

export function MediaSetup() {
  return (
    <Stack direction="column" spacing={2}>
      <VideoOrPlaceholder />
      <MediaSelect />
    </Stack>
  )
}
