import IconButton from "@mui/material/IconButton"
import { styled } from "@mui/material/styles"
import React from "react"

const CustomIconButton = styled(IconButton)(({ theme, style }) => ({
  color: theme.palette.common.white,
  ...style,
}))

export function StyledButton({
  active,
  activeTip,
  inactiveTip,
  onClick,
  style,
  children,
}) {
  return (
    <CustomIconButton
      data-tip={active ? activeTip : inactiveTip}
      onClick={onClick}
      style={style}
    >
      {children}
    </CustomIconButton>
  )
}
