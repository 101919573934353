import { Agent } from "./Agent"
import { Call, ChatMessage } from "./Call"
import { User } from "./User"

export interface State {
  user?: User
  audioEnabled: boolean
  videoEnabled: boolean
  audioDevice?: string
  videoDevice?: string
  speakerDevice?: string
  stream?: MediaStream
  peerConnection?: RTCPeerConnection
  dataConnection?: RTCDataChannel
  videoSource?: string
  call: Call
  agent: Agent
  userEnteredPassword: string
}

export enum StateAction {
  setUser = "setUser",
  end = "end",
  toggleAudio = "toggleAudio",
  toggleVideo = "toggleVideo",
  setAudioDevice = "setAudioDevice",
  setVideoDevice = "setVideoDevice",
  setSpeakerDevice = "setSpeakerDevice",
  setStream = "setStream",
  setPeerConnection = "setPeerConnection",
  setDataConnection = "setDataConnection",
  setVideoSource = "setVideoSource",
  addChatMessage = "addChatMessage",
  setChatHistory = "setChatHistory",
  setCall = "setCall",
  unhideChatMessages = "unhideChatMessages",
  setUserEnteredPassword = "setUserEnteredPassword",
  resetState = "resetState",
}

export type Action =
  | { type: StateAction.setUser; payload: User }
  | { type: StateAction.end }
  | { type: StateAction.toggleAudio }
  | { type: StateAction.toggleVideo }
  | { type: StateAction.setAudioDevice; payload: string }
  | { type: StateAction.setVideoDevice; payload: string }
  | { type: StateAction.setSpeakerDevice; payload: string }
  | { type: StateAction.setStream; payload: MediaStream }
  | { type: StateAction.setPeerConnection; payload: RTCPeerConnection }
  | { type: StateAction.setDataConnection; payload: RTCDataChannel }
  | { type: StateAction.setVideoSource; payload: string }
  | { type: StateAction.addChatMessage; payload: ChatMessage }
  | { type: StateAction.setChatHistory; payload: ChatMessage[] }
  | { type: StateAction.setCall; payload: Call }
  | { type: StateAction.unhideChatMessages }
  | { type: StateAction.setUserEnteredPassword; payload: string }
  | { type: StateAction.resetState }
