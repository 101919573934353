import { Alert, Snackbar } from "@mui/material"
import React from "react"
import { SnackbarType } from "../types/UI"

export function SnackbarWithText({
  text,
  open,
  severity,
  handleClose,
}: {
  text: string
  open: boolean
  severity: SnackbarType
  handleClose: () => void
}) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {text}
      </Alert>
    </Snackbar>
  )
}
